<template>
    <div style="position:relative;" class="custom-feature-container">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;"><span style="color:#00c8d8;">Custom Invoice Book </span>: Detailed Online Invoicing</h1>
                    <p>Are you exhausted from dealing with the hassle of traditional invoicing? Do you often feel overwhelmed by paperwork and find it challenging to manage your receipts? Look no further than Record Time! Our cutting-edge online invoicing feature organises your custom invoice book and improves your professional standing. 
                    <br/><br/>Record TIME offers a unique and customisable invoicing solution that goes beyond the limitations of standard formats. With our software, you can create invoices that are tailored to your needs, allowing you to provide comprehensive details about the services or products you've provided. In addition, you can include <a href="https://recordtime.com.au/blog/digital-docketing-with-record-time">digital dockets</a> and images to make your invoices more informative. Whether you're a freelancer, small business owner, or contractor, Record TIME empowers you to generate invoice books with clarity and organisation, eliminating any potential for confusion or ambiguity.</p>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/remote-approval-of-site-work')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
                    <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                    Get A Demo Now
                </v-btn>
                </div>
            </div>
            <br/><br/> <br/><br/>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/custom-invoice-book.webp`" alt="digital custom invoice book, easy to create" style="max-width: 100%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        
        <v-container>
            <h2>The Power of Visuals: Adding Images to Your Invoice Books </h2><br/>
            <p>Record Time takes online invoicing to the next level by utilising the strength of images. Have you ever wished you could attach relevant photos to your invoices? Our custom invoicing feature makes it simple to include pictures that support your personalised invoice, highlight completed projects, or provide proof of any repairs or maintenance work. Additionally, this level of transparency and professionalism makes a lasting impact on your clients.</p>
            <br/>
            <br/>
            <img :src="`${publicPath}assets/features-image/custom-invoice.webp`" alt="add a signature, image, and digital docket to your custom invoice book" style="max-width: 300px;display: inline-block; float: left;">
            <div style="">
                <h2>Build Trust and Security for Your Custom Invoice Book with Electronic Signatures</h2><br/>
                <p>Getting approval and acknowledgment signatures is one of the most time-consuming steps in the billing process. Record Time simplifies this process with its smooth digital signature feature integrated into its invoice template. Moreover, clients can digitally sign bills right from their devices, eliminating the need for physical paperwork and speeding up the entire invoicing process. Say goodbye to the hassle of chasing signatures and welcome a faster, more effective way of conducting business.</p>
                <br/>
                <br/><br/>
                <h2>Seamless Integration with Xero Accounting </h2><br/>
                <p>Record TIME is an innovative <a href="https://recordtime.com.au/about">business management software</a> and it incorporates Xero Accounting. It provides users with a seamless experience when it comes to managing their invoices. Moreover, this integration allows Xero Accounting users to easily access and view the invoices created within Record TIME directly from their Xero account. Furthermore, users can access their financial data in one place thanks to the smooth interface between Record TIME and Xero.</p>
                <br/><br/>
            </div>      
            <div style="clear:both;"></div>
            <br/><br/>
            <h2>How to Design a Custom Invoice with Record TIME?</h2>
            <img :src="`${publicPath}assets/features-image/create-an-online-invoice.webp`" alt="steps to create custom invoice book" style="max-width: 50%;">
            <img :src="`${publicPath}assets/features-image/create-an-online-invoice-step-two.webp`" alt="digital custom invoice book that are easy to create" style="max-width: 50%;">
            <br/><br/><br/><v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtimeapp.com.au/register')" class="mr-7 btn-1" elevation="0">
                    Get Started
                </v-btn>
        </v-container>
        
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .custom-feature-container{
        .text-section h1{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 10px !important;
            }
        }
        .banner-img{
            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 40px !important;
                height: auto !important;
                margin-top: 20px;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 20px !important;
            }
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: left;
            color: $black-pearl;
            margin-bottom: 0px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 0px;
            text-align: left;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 1200px);
      padding-right: 15px;
    }
    .right-blog1{
      width: 1200px;
      float:right;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Custom Invoice Book for Detailed Online Invoicing | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Custom invoice book to create invoices that provide comprehensive details about the services or products your business provide.'},
      {name: 'keyword', content: 'custom invoice book'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/custom-invoice-book' }
    
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
